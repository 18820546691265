import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HamburgerMenuButton } from '../../app/layout/HamburgerMenuButton';
import { MobileSidebar } from '../../app/layout/MobileSidebar';
import { Button } from '../../design/button/Button';
import { Logo } from '../../design/logo/Logo';

export const NavBar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div>
      <MobileSidebarContainer open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
      <DesktopNavBar />
      <div className="flex flex-1 flex-col md:pl-64">
        <div className="sticky flex justify-between items-center top-0 z-10 bg-white px-4 sm:px-6 md:px-8 pt-1 md:hidden">
          <div className="flex-1">
            <HamburgerMenuButton onClick={() => setSidebarOpen(true)} />
          </div>
          <Link to="/" className="flex justify-center">
          <Logo className="h-8 w-auto" />
          </Link>
          <div className="flex-1" />
        </div>
      </div>
    </div>
  );
};

const MobileSidebarContainer = ({ open, onClose }: { open: boolean; onClose: () => unknown }) => {
  return (
    <MobileSidebar
      sidebarOpen={open}
      onClose={onClose}
      navChildren={
        <div className="flex flex-col space-y-4 mt-8">
          <a
            className="font-medium text-neutral-800 mb-4 text-center"
            href="https://getaligned.notion.site/Align-s-Leveling-Framework-4ab0fc44f74f4405a127faca0fe1d554"
            target="_blank"
            rel="noreferrer"
          >
            Leveling Guide
          </a>
          <Button type="link" href="/login" block>
            Sign in
          </Button>
          <Button type="link" primary href="/onboarding" block>
            Company sign up
          </Button>
        </div>
      }
    />
  );
};

const DesktopNavBar = () => {
  return (
    <div className="hidden md:flex md:w-screen max-w-[100%]">
      <div className="flex flex-1 px-8 border-b border-gray-200 bg-white justify-center">
        <div className="flex flex-1 py-4 max-w-7xl">
          <Link to="/" className="flex flex-shrink-0 items-center">
            <Logo className="h-8 w-auto" />
          </Link>
          <nav className="flex-1 space-x-4 bg-white flex items-center justify-end">
            <a
              className="font-medium text-neutral-800 mr-4"
              href="https://getaligned.notion.site/Align-s-Leveling-Framework-4ab0fc44f74f4405a127faca0fe1d554"
              target="_blank"
              rel="noreferrer"
            >
              Leveling Guide
            </a>
            <Button type="link" href="/login">
              Sign in
            </Button>
            <Button type="link" primary href="/onboarding">
              Company sign up
            </Button>
          </nav>
        </div>
      </div>
    </div>
  );
};
